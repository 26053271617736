<template>
  <div class="newProject">
    <div class="title">个人信息</div>
    <div class="flex_center_col">
      <div class="project_title">姓名</div>
      <div class="content" v-if="!query">{{ userInfoUpdata.name }}</div>
      <el-input placeholder="请输入内容" v-model="userInfoUpdata.name" v-else style="width:198px">
      </el-input>
    </div>
    <div class="flex_center_col">
      <div class="project_title">手机号码</div>
      <div class="content">{{ user_info.mobile }}</div>
    </div>
    <div class="flex_center_col">
      <div class="project_title">性别</div>
      <div class="content" v-if="!query">
        {{
          userInfoUpdata.gender == 0
            ? "未填写"
            : userInfoUpdata.gender == 1
            ? "男"
            : "女"
        }}
      </div>
      <el-select v-model="userInfoUpdata.gender" placeholder="请选择" v-else>
        <el-option v-for="item in genderArr" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="flex_center_col">
      <div class="project_title">头像</div>
      <div class="content">
        <el-image style="width: 100px; height: 100px;margin-top:20px" :src="userInfoUpdata.avatar" :preview-src-list="srcList" v-if="!query" on-change="updataSuccess(e,b)">
        </el-image>

        <el-upload class="avatar-uploader" :action="obsUrl" :data='postData' :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" v-if="query">
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
    </div>
    <div v-if="query">点击上方图片可进行头像图片更换</div>
    <div class="btn">
      <el-button type="primary" @click="editProject" v-if="!query">编辑信息</el-button>
      <el-button type="primary" @click="editCancel" v-if="query">取消编辑</el-button>
      <el-button type="primary" @click="queryProject" v-if="query">确定修改</el-button>
    </div>
  </div>
</template>

<script>
import { user, dataCation, files, account, obsUrl } from '@/utils/api';
import location from '@/utils/province_city_district.json';
import qs from 'qs';
export default {
  name: 'userDetail',
  data() {
    return {
      srcList: [this.$store.state.user_info.avatar],
      userInfoUpdata: {
        name: this.$store.state.user_info.name,
        gender: this.$store.state.user_info.gender,
        avatar: this.$store.state.user_info.avatar
      },
      query: false,
      genderArr: [
        { label: '未填写', value: 0 },
        { label: '男', value: 1 },
        { label: '女', value: 2 }
      ],
      dialogImageUrl: this.$store.state.user_info.avatar,
      dialogVisible: false,
      disabled: false,
      file: { name: 'avatar', url: this.$store.state.user_info.avatar },
      header: {
        token: window.sessionStorage.getItem('token'),
        'current-organization-id': this.$store.state.current_project
          .organization_id
      },
      addFilesDate: { module: 'avatar', content_type: 'jpeg' },
      imageUrl: this.$store.state.user_info.avatar || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      postData: {},
      imageUrls: ''
    };
  },
  components: {},
  computed: {
    user_info() {
      return this.$store.state.user_info;
    }
  },
  methods: {
    editProject() {
      this.query = true;
    },
    editCancel() {
      this.userInfoUpdata = JSON.parse(JSON.stringify(this.cloneUserInfo));
      this.imageUrl = this.$store.state.user_info.avatar;
      this.query = false;
    },
    // 点击解散项目
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {},
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    async beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
      }
      let par = {
        content_type: file.type,
        module: 'avatar'
      };
      const res = await this.$http.get(
        files.huaweiCloudObsPolicySignature + '?' + qs.stringify(par)
      );
      this.postData = res.data;
      this.imageUrls = obsUrl + '/' + res.data.key;
      this.userInfoUpdata.avatar = this.imageUrls;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    async queryProject() {
      if (this.imageUrls) {
        this.userInfoUpdata.avatar = this.imageUrls;
      }
      if(!this.userInfoUpdata.name){
        return this.$message.error('昵称不能为空')
      }
      const res = await this.$http.post(user.updateInfo, this.userInfoUpdata);
      if (res.code === 0) {
        this.$message.success('修改成功');
        const res1 = await this.$http.get(account.info);
        this.$store.commit('setUserInfo', res1.data);
        this.query = false;
      }
    }
  },
  // 删除项目并更新 然后项目为第一个项目
  created() {
    this.cloneUserInfo = JSON.parse(JSON.stringify(this.userInfoUpdata));
  }
};
</script>
<style lang="scss" scoped>
.newProject {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 830px;
  height: 542px;
  background: #ffffff;
  box-shadow: 0px 2px 15px 0px rgba(0, 26, 59, 0.26);
  border: 1px solid #e6ecf5;
  margin: 60px auto;
  .title {
    font-size: $fontSizeMap-20;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 28px;
    margin-top: 19px;
    margin-bottom: 50px;
  }
  .flex_center_col {
    width: 420px;
    // justify-content: space-around;
    line-height: 60px;
    font-size: $fontSizeMap-14;
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
      'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
    color: #666666;
    align-items: flex-start;
  }
  .project_title {
    width: 100px;
    margin-right: 30px;
    text-align: right;
    font-size: $fontSizeMap-14;
    margin-left: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
